import React from "react";
import Layout from "../../components/Layout";

export default function sistemasDeInformacion() {
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div
                        className="services_banner"
                        style={{backgroundImage: `url(../../images/sector_salud/4.jpg)`}}
                    >
                        <h1>Sistemas de Información</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Acceso a conocimiento e información para diversos sectores de la industria, con amplio
                            énfasis en información farmacológica para laboratorios farmacéuticos, reportes de seguridad
                            sobre reacciones adversas, apoyo a la prescripción medicamentosa, seguridad de los pacientes
                            y toma de decisiones estratégicas a nivel corporativo y alta gerencia.
                            Soluciones de conocimiento accesibles para los ámbitos académico, corporativo y
                            hospitalario, organizados en las siguientes plataformas online
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}